import { Box, Button, Card, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDeleteRoomMutation, useGetRoomPaginationQuery } from "../../../../api/roomSlice";
import Controls from "../../../../form/controls";
import { getPaginationPagesCount } from "../../../../form/utils/getPaginationPagesCount";
import BasicTable from "../../../../reuseable/basicTable";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";



const DashboardRoomPage = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <DashboardRoomPagePage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const DashboardRoomPagePage = () => {
    const [pageId, setPageId] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [headers, setHeaders] = useState([]);
    const [TableData, setTableData] = useState('');
    const history = useNavigate();
    const pageChange = () => {
        history('/dashboard/add/room/page')
    }
    const { data, isLoading, isSuccess } = useGetRoomPaginationQuery(pageId);
    console.log(data);

    const [deleteRoom] = useDeleteRoomMutation();
    const deleteConfirm = (e, id) => {
        e.preventDefault();
        deleteRoom({ id });
    }
    let content;
    useEffect(() => {
        if (isLoading) {
            content = <p>"Loading..."</p>;
        } else if (isSuccess) {
            setHeaders(["Room Name", 'Image', "Price", "Capacity", "Bed Type", "Services", "Description"])
            let tableData = [];
            data.results.forEach((key) => {
                // Issue might be here
                tableData.push({
                    'Room Name': key.room_name,
                    Image: key.room_image,
                    'Price': key.price,
                    'Capacity': key.capacity,
                    'Bed Type': key.bed_type,
                    'Services': key.services,
                    id: key.id,
                    Description: key.description,
                });
            });
            setTableData(tableData)
            setTotalPages(getPaginationPagesCount(data.count, 10));
        }
    }, [isLoading, isSuccess, data])

    return (
        <Box>
            <Box style={{ marginTop: '2rem' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography style={{ fontSize: "22px", color: "black", fontWeight: "600", textAlign: "start" }}>
                        Room Page Data
                    </Typography>
                    <Button variant="contained" onClick={pageChange} style={{ fontSize: '12px', fontWeight: '600', padding: '10px', paddingLeft: '13px', paddingRight: "13px" }} startIcon={<BiPlus />}>
                        Add Data
                    </Button>
                </Stack>
                <Box style={{ marginTop: '2rem' }}>
                    <Card>
                        <Box style={{ textAlign: 'start', padding: '1.5rem' }}>
                            <Controls.SearchInput fliterPlaceholder={"Search...."} />
                        </Box>
                        <BasicTable
                            headers={headers}
                            data={TableData}
                            isDelete={true}
                            deleteColor={"#f50057"}
                            isEdit={true}
                            editColor={"#3f51b5"}
                            addColor={""}
                            tableName={"room/page"}
                            deleteConfirm={deleteConfirm}
                            setCurrentPage={setPageId}
                            setTotalPages={setTotalPages}
                            totalPages={totalPages}
                        // activeSign={["Active"]}
                        />
                    </Card>
                </Box>
            </Box>
        </Box>
    )
}
export default DashboardRoomPage;