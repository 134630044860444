import { Box } from '@mui/material';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Editor = (props) => {
    const { name, label, value, onChange, defaultValue } = props;

    const handleChange = (e) => {
        let value = e;
        onChange({
            target: {
                name, value
            }
        })
    }
    return (
        <Box style={{textAlign:"start"}}>
            <label className='label-text-design' >{label}*</label>
            <ReactQuill
                theme="snow"
                value={value}
                defaultValue={defaultValue}
                name={name}
                onChange={handleChange} />
        </Box>
    )
}

export default Editor;