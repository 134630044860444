import { InputAdornment, OutlinedInput } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import React from "react";
import Iconify from "../../reuseable/Iconify";



const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        // boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export const SearchInput = ({ filterName, onFilterName, fliterPlaceholder }) => {
    return (
        <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder={fliterPlaceholder}
            startAdornment={
                <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
            }
        />
    )
}
