import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


const userAdapter = createEntityAdapter()

const initialState = userAdapter.getInitialState()

export const userSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addUser: builder.mutation({
            query: (data) => {
                return {
                    url: '/user/register/',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Login']
        }),
        userLogin: builder.mutation({
            query: (data) => {
                return {
                    url: '/user/login/',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Login']
        }),
        getUserById: builder.query({
            query: (id) => ({
                url: `/user/user/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Login']
        }),
        userLogout: builder.mutation({
            query: (data) => {
                return {
                    url: '/user/logout/',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Login']
        }),
    })
})

export const {
    useAddUserMutation,
    useUserLoginMutation,
    useGetUserByIdQuery,
    useUserLogoutMutation,
} = userSlice

// returns the query result object
// export const selectUserResult = userSlice.endpoints.getUser.select()

// Creates memoized selector
// const selectUserData = createSelector(
//     selectUserResult,
//     UserResult => UserResult.data // normalized state object with ids & entities
// )

// //getSelectors creates these selectors and we rename them with aliases using destructuring
// export const {
//     selectAll: selectAllUser,
//     selectById: selectUserById,
//     selectIds: selectIds
//     // Pass in a selector that returns the posts slice of state
// } = userAdapter.getSelectors(state => selectUserData(state) ?? initialState)

