import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";



const ButtonController = ({ rtkQuery, data, successmessage, errormessage, className, buttonName, history = false, historyPush }) => {
    const [SuccessMessage, setSucessMessage] = useState(null);
    const [ErrorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const [DataQuery] = rtkQuery();
    const handleClick = (e) => {
        e.preventDefault();
        DataQuery(data)
            .then((res) => {
                console.log(res);
                if (res.data) {
                    setSucessMessage(successmessage)
                    setTimeout(() => {
                        if (history === true) {
                            navigate(historyPush)
                        } else {
                            setSucessMessage(null);
                        }
                    }, [3000])
                } else {
                    if (res.error) {
                        setErrorMessage(res.error.data.err ? res.error.data.err : res.error.data.email[0]);
                        setTimeout(() => {
                            setErrorMessage(null)
                        }, [3000])
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 400 || err.response.status === 401) {
                        setErrorMessage(errormessage);
                    } else if (err.response.status === 500) {
                        setErrorMessage('Server Error.');
                    } else {
                        setErrorMessage('An error occurred.');
                    }
                } else {
                    // Handle other types of errors (network, etc.)
                    setErrorMessage('Network Error.');
                }

                setTimeout(() => {
                    setErrorMessage(null);
                }, 3000);

            })
    }
    return (
        <>
            <button onClick={handleClick} className={className}>{buttonName}</button>
            {SuccessMessage === null ? null : (
                <Box className="successmessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" style={{ marginRight: "5px" }} />
                        {SuccessMessage}
                    </Typography>
                </Box>
            )}
            {ErrorMessage === null ? null : (
                <Box className="errormessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="messegeicon"
                            style={{ marginRight: "5px" }}
                        />
                        {ErrorMessage}
                    </Typography>
                </Box>
            )}

        </>
    )
}
export default ButtonController;