import React from "react";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import AddAboutPage from "../containers/dashboard/pages/aboutPage/addAboutPageData";
import DashboardAboutPage from "../containers/dashboard/pages/aboutPage/dashboardAboutPage";
import EditAboutPage from "../containers/dashboard/pages/aboutPage/editAboutPageData";
import DashboardContactPage from "../containers/dashboard/pages/dashboardContact/dashboardContactPage";
import DashboardHome from "../containers/dashboard/pages/dashboardHome/dashboardHome";
import AddPackagePage from "../containers/dashboard/pages/dashboardPackage/addPackagePageData";
import DashboardPackagePage from "../containers/dashboard/pages/dashboardPackage/dashboardPackagePage";
import EditPackagePage from "../containers/dashboard/pages/dashboardPackage/editPackagePageData";
import AddGalleryData from "../containers/dashboard/pages/galleryPage/addGalleryData";
import DashboardGalleryPage from "../containers/dashboard/pages/galleryPage/dashboardGalleryPage";
import EditGalleryData from "../containers/dashboard/pages/galleryPage/editGalleryData";
import AddHomePage from "../containers/dashboard/pages/homePage/addHomePageData";
import DashboardHomePage from "../containers/dashboard/pages/homePage/dashboardHomePage";
import EditHomePage from "../containers/dashboard/pages/homePage/editHomePageData";
import AddRoomPage from "../containers/dashboard/pages/roomPage/addRoomPageData";
import DashboardRoomPage from "../containers/dashboard/pages/roomPage/dashboardRoomPage";
import EditRoomPage from "../containers/dashboard/pages/roomPage/editRoomPageData";
import Contact from "../containers/templates/page/contact";
import Gallery from "../containers/templates/page/gallery";
import Home from "../containers/templates/page/home";
import Login from "../containers/templates/page/login";
import Package from "../containers/templates/page/ourPackage";
import PackageDetail from "../containers/templates/page/packageDetailPage";
import Room from "../containers/templates/page/room";
import RoomDetail from "../containers/templates/page/roomDetailPage";
import Signup from "../containers/templates/page/signup";
import Authentication from "./authentication";



const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/our/rooms" element={<Room />} />
                <Route path="/our/rooms/details/:id" element={<RoomDetail />} />
                <Route path="/our/package/details/:id" element={<PackageDetail />} />
                <Route path="/login" element={<Login />} />
                <Route path="/sign/up" element={<Signup />} />
                <Route path="/our/package" element={<Package />} />
                <Route path="/dashboard/home" element={<Authentication component={DashboardHome} allowRoles={['Admin']} />} />
                {/* home slider routing */}
                <Route path="/dashboard/home/page" exact element={<Authentication component={DashboardHomePage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/add/home/page" element={<Authentication component={AddHomePage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/home/page/edit/:id" element={<Authentication component={EditHomePage} allowRoles={['Admin']} />} />
                {/* about dashboard data routing */}
                <Route path="/dashboard/about/page" element={<Authentication component={DashboardAboutPage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/add/about/page" element={<Authentication component={AddAboutPage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/about/page/edit/:id" element={<Authentication component={EditAboutPage} allowRoles={['Admin']} />} />
                {/* room dashboard data routing */}
                <Route path="/dashboard/room/page" element={<Authentication component={DashboardRoomPage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/add/room/page" element={<Authentication component={AddRoomPage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/room/page/edit/:id" element={<Authentication component={EditRoomPage} allowRoles={['Admin']} />} />
                {/* package dashboard data routing */}
                <Route path="/dashboard/package/page" element={<Authentication component={DashboardPackagePage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/add/package/page" element={<Authentication component={AddPackagePage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/package/page/edit/:id" element={<Authentication component={EditPackagePage} allowRoles={['Admin']} />} />
                {/* contact dashboard data routing */}
                <Route path="/dashboard/contact/page" element={<Authentication component={DashboardContactPage} allowRoles={['Admin']} />} />
                {/* gallery dashboard data routing */}
                <Route path="/dashboard/gallery/page" element={<Authentication component={DashboardGalleryPage} allowRoles={['Admin']} />} />
                <Route path="/dashboard/add/gallery/page" element={<Authentication component={AddGalleryData} allowRoles={['Admin']} />} />
                <Route path="/dashboard/gallery/page/edit/:id" element={<Authentication component={EditGalleryData} allowRoles={['Admin']} />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Routing;