import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetPackageByIdQuery } from "../../../api/packageApi";
import { useGetRoomByIdQuery } from "../../../api/roomSlice";
import Footer from "../component/footer";
import Header from "../component/header";


const PackageDetail = () => {
    return (
        <>
            <Header />
            <PackageDetailPage />
            <Footer />
        </>
    )
}


const PackageDetailPage = () => {
    const { id } = useParams();
    const data = useGetPackageByIdQuery(id);
    return (
        <>
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-text">
                                <h2>Our Package</h2>
                                <div className="bt-option">
                                    <Link href="/">Home</Link>
                                    <span>Package Details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="room-details-section spad">
                <div className="container">
                    {
                        data !== undefined ?
                            <>
                                {
                                    data.status === 'fulfilled' ?
                                        <>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="room-details-item">
                                                        <img src={data.data.package_image} alt="" style={{width:'100%'}} />
                                                        <div className="rd-text">
                                                            <div className="rd-title">
                                                                <h3>{data.data.package_name}</h3>
                                                            </div>
                                                            <h2 style={{ textAlign: 'start' }}>Rs. {data.data.package_price * 1}<span>/Perperson</span></h2>
                                                            <table style={{textAlign:'start'}}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="">Maximum People:</td>
                                                                        <td>{data.data.package_person}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{data.data.package_highlights}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <p className="f-para" style={{textAlign:"start"}} dangerouslySetInnerHTML={{__html:data.data.description}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    {/* <div className="room-booking">
                            <h3>Your Reservation</h3>
                            <form action="#">
                                <div className="check-date">
                                    <label for="date-in">Check In:</label>
                                    <input type="text" className="date-input" id="date-in">
                                        <i className="icon_calendar"></i>
                                </div>
                                <div className="check-date">
                                    <label for="date-out">Check Out:</label>
                                    <input type="text" className="date-input" id="date-out">
                                        <i className="icon_calendar"></i>
                                </div>
                                <div className="select-option">
                                    <label for="guest">Guests:</label>
                                    <select id="guest">
                                        <option value="">3 Adults</option>
                                    </select>
                                </div>
                                <div className="select-option">
                                    <label for="room">Room:</label>
                                    <select id="room">
                                        <option value="">1 Room</option>
                                    </select>
                                </div>
                                <button type="submit">Check Availability</button>
                            </form>
                        </div> */}
                                                </div>
                                            </div>
                                        </> : null
                                }
                            </> : null
                    }
                </div>
            </section>
        </>
    )
}

export default PackageDetail;