import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useGetHomeSliderQuery } from "../../../api/homePageSlice";
import { useGetAboutQuery } from "../../../api/aboutSlice";
import { useGetRoomQuery } from "../../../api/roomSlice";
import { useGetPackageQuery } from "../../../api/packageApi";
import { useGetGalleryQuery } from "../../../api/galleryApiSlice";
import ReusableSkeleton from "../../../reuseable/reusableSekelton";
import { PiMicrophoneStageBold } from 'react-icons/pi';
import { FaParking } from "react-icons/fa";



const Home = () => {
    return (
        <>
            <Header />
            <HomePage />
            <Footer />
        </>
    )
}



const HomePage = () => {
    const home = useGetHomeSliderQuery();
    const aboutPage = useGetAboutQuery();
    const roomPage = useGetRoomQuery();
    const packagePage = useGetPackageQuery();
    const galleryImage = useGetGalleryQuery();
    return (
        <>
            <section className="hero-section" style={{ textAlign: 'start' }}>
                <div style={{ marginTop: "-4rem" }}>
                    <>
                        {
                            home !== undefined ?
                                <>
                                    {
                                        home.status === 'fulfilled' && home.status !== 'pending' ?
                                            <>
                                                {
                                                    home?.data?.ids?.length !== 0 ?
                                                        <Carousel showThumbs={false} autoPlay>
                                                            {
                                                                home?.data?.ids?.map((key, index) => (
                                                                    <div key={index}>
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="hero-text" style={{ textAlign: "start" }}>
                                                                                        <h1>{home.data.entities[key].title}</h1>
                                                                                        <p dangerouslySetInnerHTML={{ __html: home.data.entities[key].description }} />
                                                                                        <a href="/our/room" className="primary-btn">Discover Now</a>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-4 col-lg-5 offset-xl-2 offset-lg-1">
                                                                                    {/* <div className="booking-form">
                                                                            <h3>Booking Your Hotel</h3>
                                                                            <form action="#">
                                                                                <div className="check-date">
                                                                                    <label for="date-in">Check In:</label>
                                                                                    <input type="date" className="date-input" id="date-in" />
                                                                                </div>
                                                                                <div className="check-date">
                                                                                    <label for="date-out">Check Out:</label>
                                                                                    <input type="date" className="date-input" id="date-out" />
                                                                                </div>
                                                                                <div className="select-option">
                                                                                    <label for="guest">Guests:</label>
                                                                                    <select id="guest">
                                                                                        <option value="">2 Adults</option>
                                                                                        <option value="">3 Adults</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="select-option">
                                                                                    <label for="room">Room:</label>
                                                                                    <select id="room" >
                                                                                        <option value="">1 Room</option>
                                                                                        <option value="">2 Room</option>
                                                                                    </select>
                                                                                </div>
                                                                                <button type="submit">Check Availability</button>
                                                                            </form>
                                                                        </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="hs-item set-bg" style={{ position: "absolute", top: 0, width: "100%", height: "711px" }}>
                                                                            <img src={home.data.entities[key].image} style={{ width: "100%", height: "100%" }} />
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Carousel> : <ReusableSkeleton
                                                            width={'100%'}
                                                            height={'711px'}
                                                        />
                                                }
                                            </> : <ReusableSkeleton
                                                width={'100%'}
                                                height={'711px'}
                                            />
                                    }

                                </> :
                                <ReusableSkeleton
                                    width={'100%'}
                                    height={'711px'}
                                />
                        }
                    </>
                </div>
            </section>
            <section className="aboutus-section spad" id="about_us">
                <div className="container">
                    <>
                        {
                            aboutPage !== undefined ?
                                <>
                                    {
                                        aboutPage.status === 'fulfilled' ?
                                            <>
                                                {
                                                    aboutPage?.data?.ids?.length !== 0 ?
                                                        <>
                                                            {
                                                                aboutPage?.data?.ids?.map((key, index) => (
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="about-text">
                                                                                <div className="section-title">
                                                                                    <span>About Us</span>
                                                                                    <h2>{aboutPage?.data?.entities[key].title}</h2>
                                                                                </div>
                                                                                <p className="f-para" dangerouslySetInnerHTML={{ __html: aboutPage?.data?.entities[key].description }} />
                                                                                {/* <a href="#" className="primary-btn about-btn">Read More</a> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="about-pic">
                                                                                <div className="row">
                                                                                    <div className="col-sm-6">
                                                                                        <img src={aboutPage?.data?.entities[key].image1} alt="about" />
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <img src={aboutPage?.data?.entities[key].image2} alt="about" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </> : <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="about-text">
                                                                    <div className="section-title">
                                                                        <span><ReusableSkeleton width={'83.2px'} height={'17px'} /></span>
                                                                        <h2><ReusableSkeleton width={'470px'} height={'58px'} /></h2>
                                                                    </div>
                                                                    <p className="f-para"><ReusableSkeleton width={'470px'} height={'115px'} /></p>
                                                                    {/* <a href="#" className="primary-btn about-btn">Read More</a> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="about-pic">
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            {/* <img src={aboutPage.data.entities[key].image1} alt="about" /> */}
                                                                            <ReusableSkeleton width={'255px'} height={'260px'} />
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <ReusableSkeleton width={'255px'} height={'260px'} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </> : <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="about-text">
                                                        <div className="section-title">
                                                            <span><ReusableSkeleton width={'83.2px'} height={'17px'} /></span>
                                                            <h2><ReusableSkeleton width={'470px'} height={'58px'} /></h2>
                                                        </div>
                                                        <p className="f-para"><ReusableSkeleton width={'470px'} height={'115px'} /></p>
                                                        {/* <a href="#" className="primary-btn about-btn">Read More</a> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="about-pic">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                {/* <img src={aboutPage.data.entities[key].image1} alt="about" /> */}
                                                                <ReusableSkeleton width={'255px'} height={'260px'} />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <ReusableSkeleton width={'255px'} height={'260px'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </> : <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-text">
                                            <div className="section-title">
                                                <span><ReusableSkeleton width={'83.2px'} height={'17px'} /></span>
                                                <h2><ReusableSkeleton width={'470px'} height={'58px'} /></h2>
                                            </div>
                                            <p className="f-para"><ReusableSkeleton width={'470px'} height={'150px'} /></p>
                                            {/* <a href="#" className="primary-btn about-btn">Read More</a> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about-pic">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    {/* <img src={aboutPage.data.entities[key].image1} alt="about" /> */}
                                                    <ReusableSkeleton width={'255px'} height={'260px'} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <ReusableSkeleton width={'255px'} height={'260px'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
                </div>
            </section>
            <section className="hp-room-section">
                <div className="container-fluid">
                    <div className="hp-room-items">
                        <div className="row">
                            {
                                roomPage !== undefined ?
                                    <>
                                        {
                                            roomPage.status === 'fulfilled' ?
                                                <>
                                                    {
                                                        roomPage?.data?.ids?.length !== 0 ?
                                                            <>
                                                                {
                                                                    roomPage?.data?.ids?.map((key, index) => (
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="hp-room-item set-bg" style={{ backgroundImage: `url(${roomPage?.data?.entities[key].room_image})` }}>
                                                                                <div className="hr-text">
                                                                                    <h3>{roomPage?.data?.entities[key].room_name}</h3>
                                                                                    <h2>Rs. {roomPage?.data?.entities[key].price}<span>/Pernight</span></h2>
                                                                                    <table>
                                                                                        <tbody>
                                                                                            {/* <tr>
                                                                                    <td className="r-o">Size:</td>
                                                                                    <td>30 ft</td>
                                                                                </tr> */}
                                                                                            <tr>
                                                                                                <td className="r-o">Capacity:</td>
                                                                                                <td>{roomPage?.data?.entities[key].capacity}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="r-o">Bed:</td>
                                                                                                <td>{roomPage?.data?.entities[key].bed_type}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="r-o">Services:</td>
                                                                                                <td>{roomPage?.data?.entities[key].services}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <a href={`/our/rooms/details/${packagePage?.data?.entities[key].id}`} className="primary-btn">More Details</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </> : <>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </> : null
                                        }
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <span>What We Do</span>
                                <h2>Discover Our Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="service-item">
                                <i><PiMicrophoneStageBold size={70} /></i>
                                <h4>Meetings & Special Events</h4>
                                <p>Elevate your events at our resort. From corporate meetings to special celebrations, our team crafts unforgettable experiences with top-notch service and stunning venues.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="service-item">
                                <i className="flaticon-033-dinner"></i>
                                <h4>Dinning Hall</h4>
                                <p>Indulge in exquisite dining experiences at our hall. Impeccable service, delectable cuisine, and a charming ambiance await you.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="service-item">
                                <i className="flaticon-026-bed"></i>
                                <h4>Room Service</h4>
                                <p>Enjoy the ultimate convenience with our room service. Delight in delicious meals delivered right to your doorstep, ensuring a comfortable and enjoyable stay.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="service-item">
                                <i><FaParking size={70} /></i>
                                <h4>Parking Space</h4>
                                <p>Ample parking space available for your convenience. Park with ease and enjoy your visit.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="service-item">
                                <i className="flaticon-044-clock-1"></i>
                                <h4>Pick Up & Drop</h4>
                                <p>Seamless pick-up and drop-off services for your convenience. Travel with ease, knowing we've got your transportation needs covered.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="service-item">
                                <i className="flaticon-012-cocktail"></i>
                                <h4>Bar & Drink</h4>
                                <p>Sip and savor at our bar. Explore a world of crafted cocktails and premium drinks in a stylish setting.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hp-room-section">
                <div className="container-fluid">
                    <div className="hp-room-items">
                        <div className="row">
                            {
                                packagePage !== undefined ?
                                    <>
                                        {
                                            packagePage.status === 'fulfilled' ?
                                                <>
                                                    {
                                                        packagePage?.data?.ids?.length !== 0 ?
                                                            <>
                                                                {
                                                                    packagePage?.data?.ids?.map((key, index) => (
                                                                        <div className="col-lg-3 col-md-6">
                                                                            <div className="hp-room-item set-bg" style={{ backgroundImage: `url(${packagePage?.data?.entities[key].package_image})` }}>
                                                                                <div className="hr-text">
                                                                                    <h3>{packagePage?.data?.entities[key].package_name}</h3>
                                                                                    <h2>Rs. {packagePage?.data?.entities[key].package_price}<span>/per Person</span></h2>
                                                                                    <table>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="r-o">{packagePage?.data?.entities[key].package_highlights}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <a href={`/our/package/details/${packagePage?.data?.entities[key].id}`} className="primary-btn">More Details</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </> : <>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="hp-room-item set-bg" >
                                                                        <ReusableSkeleton height={'600px'} width={'352.5px'} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </> : null
                                        }
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <span>Our gallery</span>
                                <h2>View 360 Family</h2>
                            </div>
                        </div>
                    </div>
                    {
                        galleryImage !== undefined ?
                            <>
                                {
                                    galleryImage.status === 'fulfilled' ?
                                        <>
                                            {
                                                galleryImage.data.length !== 0 ?
                                                    <>
                                                        {
                                                            galleryImage?.data?.slice(0, 1).map((key, index) => (
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item set-bg" style={{ backgroundImage: `url(${key.image1})` }}>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item set-bg" style={{ backgroundImage: `url(${key.image2})` }}>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item set-bg" style={{ backgroundImage: `url(${key.image3})` }}>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8">
                                                                        <div className="blog-item small-size set-bg" style={{ backgroundImage: `url(${key.image4})` }}>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item small-size set-bg" style={{ backgroundImage: `url(${key.image5})` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </> : <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="blog-item set-bg" >
                                                                <ReusableSkeleton width={'100%'} height={'450px'} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="blog-item set-bg" >
                                                                <ReusableSkeleton width={'100%'} height={'450px'} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="blog-item set-bg">
                                                                <ReusableSkeleton width={'100%'} height={'450px'} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="blog-item small-size set-bg" >
                                                                <ReusableSkeleton width={'100%'} height={'400px'} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="blog-item small-size set-bg">
                                                                <ReusableSkeleton width={'100%'} height={'400px'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </> : null
                                }
                            </> : null
                    }
                </div>
            </section>
        </>
    )
}

export default Home;