import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


const contactAdapter = createEntityAdapter()

const initialState = contactAdapter.getInitialState()

export const contactSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContact: builder.query({
            query: () => '/home/contact/',
            transformResponse: responseData => {
                return contactAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Contact', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Contact', id }))
                ]
        }),
        getActiveContact: builder.query({
            query: () => '/home/contact/?is_active=True',
            transformResponse: responseData => {
                return contactAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Contact', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Contact', id }))
                ]
        }),
        getContactById: builder.query({
            query: (id) => ({
                url: `/home/contact/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Contact']
        }),
        addContact: builder.mutation({
            query: (imageGallery) => {
                return {
                    url: '/home/contact/',
                    method: 'POST',
                    body: imageGallery
                }
            },
            invalidatesTags: ['Contact']
        }),
        updateContact: builder.mutation({

            query: (updateImageGallery) => {
                return {
                    url: `/home/contact/${updateImageGallery.get('id')}/`,
                    method: 'PATCH',
                    body: updateImageGallery
                }
            },
            invalidatesTags: ['Contact']
        }),
        deleteContact: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/home/contact/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['Contact']
        }),
    })
})

export const {
    useGetContactQuery,
    useAddContactMutation,
    useDeleteContactMutation,
    useGetActiveContactQuery,
    useGetContactByIdQuery,
    useUpdateContactMutation,
} = contactSlice

// returns the query result object
export const selectContactResult = contactSlice.endpoints.getContact.select()

// Creates memoized selector
const selectContactData = createSelector(
    selectContactResult,
    ContactResult => ContactResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContact,
    selectById: selectContactById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = contactAdapter.getSelectors(state => selectContactData(state) ?? initialState)

