import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddHomeSliderMutation } from "../../../../api/homePageSlice";
import { useAddRoomMutation } from "../../../../api/roomSlice";
import Controls from "../../../../form/controls";
import { useForm1 } from "../../../../form/useForm";
import BlobToFile from "../../../../form/utils/BlobToFile";
import ButtonController from "../../../../reuseable/buttonController";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";




const AddRoomPage = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <AddRoomPagePage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const AddRoomPagePage = () => {
    const [Image, setImage] = useState([]);
    const [noLoop, setNoLoop] = useState(true);
    const initialFValues = {
        package_name: '',
        price: '',
        capacity: '',
        bed_type: '',
        service: '',
        description: '',
        image: [],
    }
    const { values, handleImageUpload, handleInputChange } = useForm1(
        initialFValues,
        true,
        false,
        false
    );

    useEffect(() => {
        handleImageUpload("image", Image);
    }, [Image]);

    const handleInputChangeLoop = (e) => {

        if (!noLoop) {
            setNoLoop(true)
            handleInputChange(e);

        }
        else {
            handleInputChange(e);
        }
    }

    const formData = new FormData();
    var ImageData = BlobToFile(values.image, 'image');
    if (ImageData.length !== 0) {
        formData.append('room_image', ImageData, 'image.jpg');
    }
    formData.append('package_name', values.package_name);
    formData.append('description', values.description);
    formData.append('capacity', values.capacity);
    formData.append('price', values.price);
    formData.append('bed_type', values.bed_type);
    formData.append('services', values.service);




    return (
        <Box>
            <Box style={{ marginTop: '3rem' }}>
                <Card>
                    <Box style={{ padding: "1rem" }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography style={{ fontSize: "22px", color: "black", fontWeight: "600", textAlign: "start" }}>
                                Add Room Page Data
                            </Typography>
                        </Stack>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Room Name'}
                                            name="room_name"
                                            value={values.room_name}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Capacity'}
                                            name="capacity"
                                            value={values.capacity}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={0}>
                                        <Controls.TextEditor
                                            label={'Description'}
                                            name={'description'}
                                            value={values.description}
                                            onChange={handleInputChangeLoop}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Room Price'}
                                            name="price"
                                            value={values.price}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Bed Type'}
                                            name="bed_type"
                                            value={values.bed_type}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Room Services'}
                                            name="service"
                                            value={values.service}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Controls.Image setFile={setImage} aspectRatio={47 / 60} />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <ButtonController buttonName={'Submit'} rtkQuery={useAddRoomMutation} data={formData} history={true} historyPush={"/dashboard/room/page"} successmessage={"Roompage data added successfully."} errormessage={'Some error occur.Please try again later.'} className="button-design-class" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default AddRoomPage