import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const imageGallerysAdapter = createEntityAdapter()

const initialState = imageGallerysAdapter.getInitialState()

export const imageGallerySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGallery: builder.query({
            query: (id) => ({
                url: `/home/gallery/`,
                method: "GET",
            }),
            providesTags: ["ImageGallerys"],
        }),
        getPaginationImageGallerys: builder.query({
            query: (id) => ({
                url: `/home/gallery-pagination/?page=${id}`,
                method: "GET",
            }),
            providesTags: ["ImageGallerys"],
        }),
        getImageGallerysPagination: builder.query({
            query: (id) => ({
                url: `/home/gallery-pagination/?page=${id}`,
                method: 'GET',
            }),
            providesTags: ['ImageGallerys']
        }),
        getImageGallerysById: builder.query({
            query: (id) => ({
                url: `/home/gallery/${id}/`,
                method: 'GET',
            }),
            providesTags: ['ImageGallerys']
        }),
        addImageGallery: builder.mutation({
            query: (imageGallery) => {
                return {
                    url: '/home/gallery/',
                    method: 'POST',
                    body: imageGallery
                }
            },
            invalidatesTags: ['ImageGallerys']
        }),
        updateImageGallery: builder.mutation({

            query: (updateImageGallery) => {
                return {
                    url: `/home/gallery/${updateImageGallery.get('id')}/`,
                    method: 'PATCH',
                    body: updateImageGallery
                }
            },
            invalidatesTags: ['ImageGallerys']
        }),
        deleteImageGallery: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/home/gallery/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['ImageGallerys']
        }),
    })
})

export const {
    useGetGalleryQuery,
    useAddImageGalleryMutation,
    useUpdateImageGalleryMutation,
    useDeleteImageGalleryMutation,
    useGetImageGallerysByIdQuery,
    useGetPaginationImageGallerysQuery,
    useGetImageGallerysPaginationQuery,
} = imageGallerySlice

// returns the query result object
export const selectImageGallerysResult = imageGallerySlice.endpoints.getGallery.select()

// Creates memoized selector
const selectImageGalleryssData = createSelector(
    selectImageGallerysResult,
    imageGallerysResult => imageGallerysResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllImageGallerys,
    selectById: selectImageGalleryById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = imageGallerysAdapter.getSelectors(state => selectImageGalleryssData(state) ?? initialState)

