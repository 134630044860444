import { Box, Button, Card, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDeleteHomeSliderMutation, useGetHomeSliderQuery } from "../../../../api/homePageSlice";
import Controls from "../../../../form/controls";
import BasicTable from "../../../../reuseable/basicTable";
import ScrollBar from "../../../../reuseable/scrollBar";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";



const DashboardHomePage = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <DashboardHomePagePage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const DashboardHomePagePage = () => {
    const history = useNavigate();
    const pageChange = () => {
        history('/dashboard/add/home/page')
    }
    const { data, isLoading, isSuccess } = useGetHomeSliderQuery();

    const [deleteHomeSlider] = useDeleteHomeSliderMutation();
    const deleteConfirm = (e, id) => {
        e.preventDefault();
        deleteHomeSlider({ id });
    }

    let headers = [];
    let tableData = [];
    let content;
    if (isLoading) {
        content = <p>"Loading..."</p>;
    } else if (isSuccess) {
        headers = ["Title", 'Image', "Description"];
        tableData = [];
        data.ids.forEach((key) => {
            tableData.push({
                Title: data.entities[key].title,
                Image: data.entities[key].image,
                id: data.entities[key].id,
                Description: data.entities[key].description,
            });
        });
    }

    console.log(tableData);


    return (
        <Box>
            <Box style={{ marginTop: '2rem' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography style={{ fontSize: "22px", color: "black", fontWeight: "600", textAlign: "start" }}>
                        Home Page Data
                    </Typography>
                    <Button variant="contained" onClick={pageChange} style={{ fontSize: '12px', fontWeight: '600', padding: '10px', paddingLeft: '13px', paddingRight: "13px" }} startIcon={<BiPlus />}>
                        Add Data
                    </Button>
                </Stack>
                <Box style={{ marginTop: '2rem' }}>
                    <Card>
                        <Box style={{ textAlign: 'start', padding: '1.5rem' }}>
                            <Controls.SearchInput fliterPlaceholder={"Search...."} />
                        </Box>
                        <BasicTable
                            headers={headers}
                            data={tableData}
                            isDelete={true}
                            deleteColor={"#f50057"}
                            isEdit={true}
                            editColor={"#3f51b5"}
                            addColor={""}
                            tableName={"home/page"}
                            deleteConfirm={deleteConfirm}
                            // activeSign={["Active"]}
                        />
                    </Card>
                </Box>
            </Box>
        </Box>
    )
}
export default DashboardHomePage;