import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


const aboutAdapter = createEntityAdapter()

const initialState = aboutAdapter.getInitialState()

export const aboutSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAbout: builder.query({
            query: () => '/home/about/',
            transformResponse: responseData => {
                return aboutAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'About', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'About', id }))
                ]
        }),
        getActiveAbout: builder.query({
            query: () => '/home/about/?is_active=True',
            transformResponse: responseData => {
                return aboutAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'About', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'About', id }))
                ]
        }),
        getAboutById: builder.query({
            query: (id) => ({
                url: `/home/about/${id}/`,
                method: 'GET',
            }),
            providesTags: ['About']
        }),
        addAbout: builder.mutation({
            query: (imageGallery) => {
                return {
                    url: '/home/about/',
                    method: 'POST',
                    body: imageGallery
                }
            },
            invalidatesTags: ['About']
        }),
        updateAbout: builder.mutation({

            query: (updateImageGallery) => {
                return {
                    url: `/home/about/${updateImageGallery.get('id')}/`,
                    method: 'PATCH',
                    body: updateImageGallery
                }
            },
            invalidatesTags: ['About']
        }),
        deleteAbout: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/home/about/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['About']
        }),
    })
})

export const {
    useGetAboutQuery,
    useAddAboutMutation,
    useDeleteAboutMutation,
    useGetActiveAboutQuery,
    useGetAboutByIdQuery,
    useUpdateAboutMutation,
} = aboutSlice

// returns the query result object
export const selectAboutResult = aboutSlice.endpoints.getAbout.select()

// Creates memoized selector
const selectAboutData = createSelector(
    selectAboutResult,
    AboutResult => AboutResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAbout,
    selectById: selectAboutById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = aboutAdapter.getSelectors(state => selectAboutData(state) ?? initialState)

