import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddImageGalleryMutation } from "../../../../api/galleryApiSlice";
import { useAddHomeSliderMutation } from "../../../../api/homePageSlice";
import { useAddRoomMutation } from "../../../../api/roomSlice";
import Controls from "../../../../form/controls";
import { useForm1 } from "../../../../form/useForm";
import BlobToFile from "../../../../form/utils/BlobToFile";
import ButtonController from "../../../../reuseable/buttonController";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";




const AddGalleryData = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <AddGalleryDataPage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const AddGalleryDataPage = () => {
    const [Image1, setImage1] = useState([]);
    const [Image2, setImage2] = useState([]);
    const [Image3, setImage3] = useState([]);
    const [Image4, setImage4] = useState([]);
    const [Image5, setImage5] = useState([]);
    const initialFValues = {
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        image5: [],
    }
    const { values, handleImageUpload } = useForm1(
        initialFValues,
        true,
        false,
        false
    );

    useEffect(() => {
        handleImageUpload("image1", Image1);
    }, [Image1]);

    useEffect(() => {
        handleImageUpload("image2", Image2);
    }, [Image2]);

    useEffect(() => {
        handleImageUpload("image3", Image3);
    }, [Image3]);

    useEffect(() => {
        handleImageUpload("image4", Image4);
    }, [Image4]);

    useEffect(() => {
        handleImageUpload("image5", Image5);
    }, [Image5]);


    const formData = new FormData();
    var ImageData = BlobToFile(values.image1, 'image1');
    if (ImageData.length !== 0) {
        formData.append('image1', ImageData, 'image1.jpg');
    }

    var ImageData1 = BlobToFile(values.image2, 'image2');
    if (ImageData1.length !== 0) {
        formData.append('image2', ImageData1, 'image2.jpg');
    }

    var ImageData3 = BlobToFile(values.image3, 'image3');
    if (ImageData3.length !== 0) {
        formData.append('image3', ImageData3, 'image3.jpg');
    }

    var ImageData4 = BlobToFile(values.image4, 'image4');
    if (ImageData4.length !== 0) {
        formData.append('image4', ImageData4, 'image4.jpg');
    }

    var ImageData5 = BlobToFile(values.image5, 'image5');
    if (ImageData5.length !== 0) {
        formData.append('image5', ImageData5, 'image5.jpg');
    }


    return (
        <Box>
            <Box style={{ marginTop: '3rem' }}>
                <Card>
                    <Box style={{ padding: "1rem" }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography style={{ fontSize: "22px", color: "black", fontWeight: "600", textAlign: "start" }}>
                                Add Gallery Data
                            </Typography>
                        </Stack>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Box marginBottom={3}>
                                        <Controls.Image setFile={setImage1} aspectRatio={37 / 45} />
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Controls.Image setFile={setImage2} aspectRatio={37 / 45} />
                                    </Box>
                                    <Box marginBottom={0}>
                                        <Controls.Image setFile={setImage3} aspectRatio={37 / 45} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box marginBottom={3}>
                                        <Controls.Image setFile={setImage4} aspectRatio={77 / 40} />
                                    </Box>
                                    <Controls.Image setFile={setImage5} aspectRatio={37 / 40} />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <ButtonController buttonName={'Submit'} rtkQuery={useAddImageGalleryMutation} data={formData} history={true} historyPush={"/dashboard/gallery/page"} successmessage={"Gallery data added successfully."} errormessage={'Some error occur.Please try again later.'} className="button-design-class" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default AddGalleryData