import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useUserLoginMutation } from "../../../api/userSlice";
import Header from '../component/header';
import Footer from "../component/footer";
import { Box, Grid, Typography } from "@mui/material";

const Login = () => {
    return (
        <>
            <Header />
            <LoginPage />
            <Footer />
        </>
    );
};

const LoginPage = () => {
    const [email, setEmail] = useState(Cookies.get('email'));
    const [password, setPassword] = useState(Cookies.get('password'));
    const [loginAPI] = useUserLoginMutation();
    const [SuccessMessege, setSuccesMessege] = useState(null);
    const [ErrorMessege, setErrorMessege] = useState(null);
    const history = useNavigate();

    const rememberCheck = useRef(null);

    const onEmail = (e) => setEmail(e.target.value);
    const onPassword = (e) => setPassword(e.target.value);

    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };


    const clickBtn = (e) => {
        e.preventDefault();
        loginAPI({ email: email, password: password })
            .then((res) => {
                console.log(res.data);
                if (res.data) {
                    if (rememberCheck.current.checked === true) {
                        Cookies.set("email", email);
                        Cookies.set("password", password);
                    } else {
                        Cookies.remove("email");
                        Cookies.remove("password");
                    }
                    Cookies.set("access", res.data.tokens.access);
                    Cookies.set("refresh", res.data.tokens.refresh);
                    Cookies.set("user_email", res.data.email);
                    Cookies.set('id', res.data.tokens.id);
                    setSuccesMessege("Successfully Logged In.");
                    setTimeout(() => {
                        if (res.data.tokens.is_staff === true) {
                            Cookies.set('user_role', 'Admin')
                            history("/dashboard/home");
                        } else {
                            history("/");
                        }
                    }, [3000]);
                } else {
                    if (res.error) {
                        setErrorMessege(res.error.data.err ? res.error.data.err : res.error.data.email[0]);
                        setTimeout(() => {
                            setErrorMessege(null)
                        }, [3000])
                    }
                }
            })
            .catch((err) => {
                setErrorMessege("Email or Password doesnot Match.");
                setTimeout(() => {
                    setErrorMessege(null);
                }, [3000]);
            });
    };

    return (
        <Box className="signinmargin res-padding" style={{ textAlign: "start" }}>
            <Grid container spacing={0}>
                <Grid item xs={4}></Grid>
                <Grid
                    item
                    sm={12}
                    md={4}
                    lg={4}
                    xs={12}
                    style={{ margin: "0 auto" }}
                    className="signinauto"
                >
                    <Grid container>
                        <Grid item md={12} xs={12} className="signinleft ">
                            <Box>
                                <Grid
                                    item
                                    xs={3}
                                    style={{ margin: "0 auto" }}
                                    className="signinleftmargin "
                                >
                                    <Box>
                                        <Typography className="signintext">Login</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="signintext1">
                                            or use your account
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box className="newforms">
                                <Box>
                                    <lable className="emailtext">Email*</lable>
                                    <input
                                        type="email"
                                        onChange={onEmail}
                                        className="titleform2"
                                        placeholder="Enter Your Email"
                                        value={email}
                                    />
                                </Box>
                                <Box className="passwordbtn mt-3">
                                    <lable className="emailtext">Password*</lable>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={onPassword}
                                                className="titleform3"
                                                placeholder="Enter Your Password"
                                                value={password}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box className="titleform4" onClick={handlePasswordToggle}>
                                                {showPassword ? < AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Box className="remembermargin">
                                            <input type="checkbox" ref={rememberCheck} />
                                            <lable className="fs_13" style={{ fontSize: '14px', color: "black", fontWeight: "600", marginLeft: "3px" }}>Remember Me</lable>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box className="forgotpassword1">
                                            <Link
                                                className="forgotpassword"
                                                to="/forgetpassword/emailverification"
                                            >
                                                Forgot Password?
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <button
                                    onClick={clickBtn}
                                    className="signinbutton"
                                    style={{ border: "none" }}
                                >
                                    Login
                                </button>
                                <Box >
                                    <Typography style={{ marginTop: "2rem", color: "black", textAlign: 'center', fontSize: "15px", fontWeight: "500" }}>Don't have an account?
                                        <Link
                                            className="forgotpassword"
                                            to="/sign/up"
                                            style={{ fontSize: "16px", marginLeft: '3px', fontWeight: '700' }}
                                        >
                                            Sign up
                                        </Link></Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            {SuccessMessege === null ? null : (
                <Box className="successmessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                        {SuccessMessege}
                    </Typography>
                </Box>
            )}
            {ErrorMessege === null ? null : (
                <Box className="errormessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="messegeicon"
                        />
                        {ErrorMessege}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default Login;
