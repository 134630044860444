import { TextField } from '@mui/material';
import React from 'react'

export default function Input(props) {

    const { name, label, value, error = null, onChange, defaultValue } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            style={{
                borderBlockColor: 'white',
                width: '100%',
                marginTop:'-0px',
                marginLeft:'0px'
            }}
            className="input-height"
            {...(error && { error: true, helperText: error })}
        />
    )
}
