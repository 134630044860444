import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


const packageAdapter = createEntityAdapter()

const initialState = packageAdapter.getInitialState()

export const packageSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPackage: builder.query({
            query: () => '/misc/package/',
            transformResponse: responseData => {
                return packageAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Package', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Package', id }))
                ]
        }),
        getPaginationPackage: builder.query({
            query: (id) => ({
                url: `/misc/package-pagination/?page=${id}`,
                method: "GET",
              }),
              providesTags: ["Package"],
        }),
        getPackageById: builder.query({
            query: (id) => ({
                url: `/misc/package/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Package']
        }),
        addPackage: builder.mutation({
            query: (imageGallery) => {
                return {
                    url: '/misc/package/',
                    method: 'POST',
                    body: imageGallery
                }
            },
            invalidatesTags: ['Package']
        }),
        updatePackage: builder.mutation({

            query: (updateImageGallery) => {
                return {
                    url: `/misc/package/${updateImageGallery.get('id')}/`,
                    method: 'PATCH',
                    body: updateImageGallery
                }
            },
            invalidatesTags: ['Package']
        }),
        deletePackage: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/misc/package/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['Package']
        }),
    })
})

export const {
    useGetPackageQuery,
    useAddPackageMutation,
    useDeletePackageMutation,
    useGetPaginationPackageQuery,
    useGetPackageByIdQuery,
    useUpdatePackageMutation,
} = packageSlice

// returns the query result object
export const selectPackageResult = packageSlice.endpoints.getPackage.select()

// Creates memoized selector
const selectPackageData = createSelector(
    selectPackageResult,
    PackageResult => PackageResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllPackage,
    selectById: selectPackageById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = packageAdapter.getSelectors(state => selectPackageData(state) ?? initialState)

