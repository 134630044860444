import React from "react";
import Header from "../component/header";
import Slider1 from "../../../static/images/hero/hero-1.jpg";
import Footer from "../component/footer";
import { useForm1 } from "../../../form/useForm";
import { useAddContactMutation } from "../../../api/contactSlice";
import ButtonController from "../../../reuseable/buttonController";


const Contact = () => {
    return (
        <>
            <Header />
            <ContactPage />
            <Footer />
        </>
    )
}


const ContactPage = () => {
    const initialFValues = {
        full_name: '',
        email: "",
        contact_no: '',
        subject: '',
        message: '',
    }
    const { values, handleInputChange } = useForm1(
        initialFValues,
        true,
        false,
        false
    );


    return (
        <>
            <div className="" >
                <div class="hs-item set-bg">
                    <div className="blog-item set-bg" style={{ backgroundImage: `url(${Slider1})` }}>
                        <div className="container">
                            <div style={{ paddingTop: "9rem" }}>
                                <span style={{ color: "white", textAlign: 'center' }}>Get in Touch</span>
                                <h2 style={{ color: "white", textAlign: 'center', marginTop: '14px', fontWeight: '700' }}>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="contact-section spad" style={{ textAlign: 'start' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="contact-text">
                                <h2>Contact Info</h2>
                                <p>
                                    The View 360 Resort offers breathtaking panoramic vistas that will leave you in awe from every angle.</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="c-o">Address:</td>
                                            <td>Kalu pandey, Kathmandu, Nepal</td>
                                        </tr>
                                        <tr>
                                            <td class="c-o">Phone:</td>
                                            <td>+977-9762288016</td>
                                        </tr>
                                        <tr>
                                            <td class="c-o">Email:</td>
                                            <td>view360resort@gmail.com</td>
                                        </tr>
                                        {/* <tr>
                                            <td class="c-o">Fax:</td>
                                            <td>+977-1234567</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-7 offset-lg-1">
                            <form action="#" class="contact-form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input type="text" placeholder="Your Full Name" name="full_name" value={values.full_name} onChange={handleInputChange} />
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="email" placeholder="Your Email" name="email" value={values.email} onChange={handleInputChange} />
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="number" placeholder="Your Contact Number" name="contact_no" value={values.contact_no} onChange={handleInputChange} />
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="text" placeholder="Your Subject" name="subject" value={values.subject} onChange={handleInputChange} />
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea placeholder="Your Message" name="message" values={values.message} onChange={handleInputChange}></textarea>
                                        <ButtonController buttonName={'Submit Now'} rtkQuery={useAddContactMutation} data={values} history={false} successmessage={"Message successfully send."} errormessage={'Some error occur.Please try again later.'} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.0606825994123!2d-72.8735845851828!3d40.760690042573295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85b24c9274c91%3A0xf310d41b791bcb71!2sWilliam%20Floyd%20Pkwy%2C%20Mastic%20Beach%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1578582744646!5m2!1sen!2sbd"
                            height="470" style={{ border: '0' }} allowfullscreen=""></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;