import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddHomeSliderMutation } from "../../../../api/homePageSlice";
import { useAddPackageMutation } from "../../../../api/packageApi";
import { useAddRoomMutation } from "../../../../api/roomSlice";
import Controls from "../../../../form/controls";
import { useForm1 } from "../../../../form/useForm";
import BlobToFile from "../../../../form/utils/BlobToFile";
import ButtonController from "../../../../reuseable/buttonController";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";




const AddPackagePage = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <AddPackagePagePage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const AddPackagePagePage = () => {
    const [Image, setImage] = useState([]);
    const [noLoop, setNoLoop] = useState(true);
    const initialFValues = {
        package_name: '',
        package_price: '',
        package_person: '',
        package_highlights: '',
        package_description: '',
        package_image: [],
    }
    const { values, handleImageUpload, handleInputChange } = useForm1(
        initialFValues,
        true,
        false,
        false
    );

    useEffect(() => {
        handleImageUpload("package_image", Image);
    }, [Image]);

    const handleInputChangeLoop = (e) => {

        if (!noLoop) {
            setNoLoop(true)
            handleInputChange(e);

        }
        else {
            handleInputChange(e);
        }
    }

    const formData = new FormData();
    var ImageData = BlobToFile(values.package_image, 'package_image');
    if (ImageData.length !== 0) {
        formData.append('package_image', ImageData, 'package_image.jpg');
    }
    formData.append('package_name', values.package_name);
    formData.append('package_description', values.package_description);
    formData.append('package_person', values.package_person);
    formData.append('package_price', values.package_price);
    formData.append('package_highlights', values.package_highlights);




    return (
        <Box>
            <Box style={{ marginTop: '3rem' }}>
                <Card>
                    <Box style={{ padding: "1rem" }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography style={{ fontSize: "22px", color: "black", fontWeight: "600", textAlign: "start" }}>
                                Add Package Page Data
                            </Typography>
                        </Stack>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Package Name'}
                                            name="package_name"
                                            value={values.package_name}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Package Person'}
                                            name="package_person"
                                            value={values.package_person}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={0}>
                                        <Controls.TextEditor
                                            label={'Package Description'}
                                            name={'package_description'}
                                            value={values.package_description}
                                            onChange={handleInputChangeLoop}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Package Price'}
                                            name="package_price"
                                            value={values.package_price}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Controls.Input
                                            label={'Package Highlights'}
                                            name="package_highlights"
                                            value={values.package_highlights}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                    <Controls.Image setFile={setImage} aspectRatio={47 / 60} />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <ButtonController buttonName={'Submit'} rtkQuery={useAddPackageMutation} data={formData} history={true} historyPush={"/dashboard/package/page"} successmessage={"Packagepage data added successfully."} errormessage={'Some error occur.Please try again later.'} className="button-design-class" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default AddPackagePage