import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useAddUserMutation, useUserLoginMutation } from "../../../api/userSlice";
import Header from '../component/header';
import Footer from "../component/footer";
import { Box, Grid, Typography } from "@mui/material";
import ButtonController from "../../../reuseable/buttonController";
import { useForm1 } from "../../../form/useForm";

const Signup = () => {
    return (
        <>
            <Header />
            <SignupPage />
            <Footer />
        </>
    );
};

const SignupPage = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = useState(false);

    const handlePasswordToggle1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [SuccessMessege, setSucessMessage] = useState(null);
    const [ErrorMessege, setErrorMessage] = useState(null);

    const initialFValues = {
        email: '',
        first_name: '',
        last_name: '',
        address: '',
        contact_no: '',
        password: '',
        c_password: '',
    };

    const {
        values,
        handleInputChange,
    } = useForm1(initialFValues, true);

    const history = useNavigate();

    const [SignUp] = useAddUserMutation();

    const createUser = (e) => {
        e.preventDefault();
        if (values.password === values.c_password) {
            SignUp(values)
                .then((res) => {
                    if (res.data) {
                        setSucessMessage('Successfully Register.Please verify your email.')
                        setTimeout(() => {
                            history('/login')
                        }, [3000])
                    } else {
                        if (res.error) {
                            setErrorMessage(res.error.data.data.error);
                            setTimeout(() => {
                                setErrorMessage(null)
                            }, [3000])
                        } 
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status === 400 || err.response.status === 401) {
                            setErrorMessage('Some error occur. Please try again later.');
                        } else if (err.response.status === 500) {
                            setErrorMessage('Server Error.');
                        } else {
                            setErrorMessage('An error occurred.');
                        }
                    } else {
                        // Handle other types of errors (network, etc.)
                        setErrorMessage('Network Error.');
                    }

                    setTimeout(() => {
                        setErrorMessage(null);
                    }, 3000);
                })
        } else {
            setErrorMessage('Password does not match.')
            setTimeout(() => {
                setErrorMessage(null);
            }, 3000);
        }
    }


    return (
        <Box className="signinmargin res-padding" style={{ textAlign: "start" }}>
            <Grid container spacing={0}>
                <Grid item xs={3}></Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                    lg={6}
                    xs={12}
                    style={{ margin: "0 auto" }}
                    className="signinauto"
                >
                    <Grid container>
                        <Grid item md={12} xs={12} className="signinleft ">
                            <Box>
                                <Grid
                                    item
                                    xs={3}
                                    style={{ margin: "0 auto" }}
                                    className="signinleftmargin "
                                >
                                    <Box>
                                        <Typography className="signintext">Sign Up</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="signintext1">
                                            or use your account
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box className="newforms">
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <lable className="emailtext">First Name*</lable>
                                            <input
                                                name="first_name"
                                                type="text"
                                                className="titleform2"
                                                placeholder="Enter Your First Name"
                                                value={values.first_name}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className='mt-3'>
                                            <lable className="emailtext">Email*</lable>
                                            <input
                                                name="email"
                                                type="email"
                                                className="titleform2"
                                                placeholder="Enter Your Email"
                                                value={values.email}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className='mt-3'>
                                            <lable className="emailtext">Contact Number*</lable>
                                            <input
                                                name="contact_no"
                                                type="number"
                                                className="titleform2"
                                                placeholder="Enter Your Contact Number"
                                                value={values.contact_no}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="passwordbtn mt-3">
                                            <lable className="emailtext">Confirm Password*</lable>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <input
                                                        name="c_password"
                                                        type={showPassword1 ? 'text' : 'password'}
                                                        className="titleform3"
                                                        placeholder="Enter Your Confirm Password"
                                                        value={values.c_password}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Box className="titleform4" onClick={handlePasswordToggle1}>
                                                        {showPassword1 ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <lable className="emailtext">Last Name*</lable>
                                            <input
                                                name="last_name"
                                                type="text"
                                                className="titleform2"
                                                placeholder="Enter Your Last Name"
                                                value={values.last_name}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className='mt-3'>
                                            <lable className="emailtext">Address*</lable>
                                            <input
                                                name="address"
                                                type="text"
                                                className="titleform2"
                                                placeholder="Enter Your Address"
                                                value={values.address}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="passwordbtn mt-3">
                                            <lable className="emailtext">Password*</lable>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <input
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        className="titleform3"
                                                        placeholder="Enter Your Password"
                                                        value={values.password}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Box className="titleform4" onClick={handlePasswordToggle}>
                                                        {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box className="mt-1">
                                    <button
                                        className="signinbutton"
                                        style={{ border: "none" }}
                                        onClick={createUser}
                                    >
                                        Sign up
                                    </button>
                                </Box>
                                <Box >
                                    <Typography style={{ marginTop: "2rem", color: "black", textAlign: 'center', fontSize: "15px", fontWeight: "500" }}>Already have an account?
                                        <Link
                                            className="forgotpassword"
                                            to="/login"
                                            style={{ fontSize: "16px", marginLeft: '3px', fontWeight: '700' }}
                                        >
                                            Login
                                        </Link></Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
            {SuccessMessege === null ? null : (
                <Box className="successmessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                        {SuccessMessege}
                    </Typography>
                </Box>
            )}
            {ErrorMessege === null ? null : (
                <Box className="errormessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="messegeicon"
                        />
                        {ErrorMessege}
                    </Typography>
                </Box>
            )}
        </Box >
    );
};

export default Signup;
