import React, { useState } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Slider1 from "../../../static/images/hero/hero-1.jpg"
import { useGetGalleryQuery, useGetImageGallerysPaginationQuery } from "../../../api/galleryApiSlice";
import { Box, Pagination, Stack } from "@mui/material";
import usePagination from "../../../form/utils/usePagination";
import ReusableSkeleton from "../../../reuseable/reusableSekelton";




const Gallery = () => {
    return (
        <>
            <Header />
            <GalleryPage />
            <Footer />
        </>
    )
}


const GalleryPage = () => {
    const [pageId, setPageId] = useState(1);
    const data = useGetImageGallerysPaginationQuery(pageId);
    const { isLoading, isSuccess } = useGetImageGallerysPaginationQuery(pageId);

    let AllData = [];
    let count;
    let content;
    if (isLoading) {
        content = <p>Loading....</p>
    } else if (isSuccess) {
        data.data.results.forEach((key) => {
            AllData.push({
                id: key.id,
                image1: key.image1,
                image2: key.image2,
                image3: key.image3,
                image4: key.image4,
                image5: key.image5
            })
        })
        var dataCount = data.data.count / 10
        count = Math.ceil(dataCount);
    }

    const [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const _DATA = usePagination(AllData, count, PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        setPageId(p);
    };

    return (
        <>
            <div className="" >
                <div class="hs-item set-bg">
                    <div className="blog-item set-bg" style={{ backgroundImage: `url(${Slider1})` }}>
                        <div className="container">
                            <div style={{ paddingTop: "9rem" }}>
                                <span style={{ color: "white", textAlign: 'center' }}>Our gallery</span>
                                <h2 style={{ color: "white", textAlign: 'center', marginTop: '14px', fontWeight: '700' }}>View 360 Family</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">

                            </div>
                        </div>
                    </div>
                    {
                        data !== undefined ?
                            <>
                                {
                                    data.status === 'fulfilled' ?
                                        <>
                                            {
                                                data.data.results.length !== 0 ?
                                                    <>
                                                        {
                                                            data.data.results.map((key, index) => (
                                                                <div className="row" key={index}>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item set-bg" style={{ backgroundImage: `url(${key.image1})` }}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item set-bg" style={{ backgroundImage: `url(${key.image2})` }}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item set-bg" style={{ backgroundImage: `url(${key.image3})` }}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8">
                                                                        <div className="blog-item small-size set-bg" style={{ backgroundImage: `url(${key.image4})` }}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="blog-item small-size set-bg" style={{ backgroundImage: `url(${key.image5})` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </> : <>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="blog-item set-bg" >
                                                                    <ReusableSkeleton width={'100%'} height={'450px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="blog-item set-bg" >
                                                                    <ReusableSkeleton width={'100%'} height={'450px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="blog-item set-bg">
                                                                    <ReusableSkeleton width={'100%'} height={'450px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <div className="blog-item small-size set-bg" >
                                                                    <ReusableSkeleton width={'100%'} height={'400px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="blog-item small-size set-bg">
                                                                    <ReusableSkeleton width={'100%'} height={'400px'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="blog-item set-bg" >
                                                                    <ReusableSkeleton width={'100%'} height={'450px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="blog-item set-bg" >
                                                                    <ReusableSkeleton width={'100%'} height={'450px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="blog-item set-bg">
                                                                    <ReusableSkeleton width={'100%'} height={'450px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <div className="blog-item small-size set-bg" >
                                                                    <ReusableSkeleton width={'100%'} height={'400px'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="blog-item small-size set-bg">
                                                                    <ReusableSkeleton width={'100%'} height={'400px'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </> : null
                                }
                            </> : null
                    }
                    <Box
                        style={{
                            marginTop: "20px",
                            textAlign: "center",
                            paddingBottom: "1rem",
                            paddingLeft: "1rem",
                        }}
                    >
                        <Stack spacing={2} style={{ textAlign: "center" }}>
                            <Pagination
                                count={count}
                                size="small"
                                page={page}
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Stack>
                    </Box>
                </div>
            </section>
        </>
    )
}


export default Gallery;