import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


const homeSliderAdapter = createEntityAdapter()

const initialState = homeSliderAdapter.getInitialState()

export const homeSliderSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getHomeSlider: builder.query({
            query: () => '/home/home/',
            transformResponse: responseData => {
                return homeSliderAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Homepage', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Homepage', id }))
                ]
        }),
        getActiveHomeSlider: builder.query({
            query: () => '/home/home/?is_active=True',
            transformResponse: responseData => {
                return homeSliderAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Homepage', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Homepage', id }))
                ]
        }),
        getHomeSliderById: builder.query({
            query: (id) => ({
                url: `/home/home/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Homepage']
        }),
        addHomeSlider: builder.mutation({
            query: (imageGallery) => {
                return {
                    url: '/home/home/',
                    method: 'POST',
                    body: imageGallery
                }
            },
            invalidatesTags: ['Homepage']
        }),
        updateHomeSlider: builder.mutation({

            query: (updateImageGallery) => {
                return {
                    url: `/home/home/${updateImageGallery.get('id')}/`,
                    method: 'PATCH',
                    body: updateImageGallery
                }
            },
            invalidatesTags: ['Homepage']
        }),
        deleteHomeSlider: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/home/home/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['Homepage']
        }),
    })
})

export const {
    useGetHomeSliderQuery,
    useAddHomeSliderMutation,
    useDeleteHomeSliderMutation,
    useGetActiveHomeSliderQuery,
    useGetHomeSliderByIdQuery,
    useUpdateHomeSliderMutation,
} = homeSliderSlice

// returns the query result object
export const selectHomeSliderResult = homeSliderSlice.endpoints.getHomeSlider.select()

// Creates memoized selector
const selectHomeSliderData = createSelector(
    selectHomeSliderResult,
    homeSliderResult => homeSliderResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllHomeSlider,
    selectById: selectHomeSliderById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = homeSliderAdapter.getSelectors(state => selectHomeSliderData(state) ?? initialState)

