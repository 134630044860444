import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Room1 from '../../../static/images/room/room-1.jpg';
import Room2 from '../../../static/images/room/room-2.jpg';
import Room3 from '../../../static/images/room/room-3.jpg';
import Room4 from '../../../static/images/room/room-4.jpg';
import Room5 from '../../../static/images/room/room-5.jpg';
import { Link } from "react-router-dom";
import { useGetPackageQuery } from "../../../api/packageApi";
import ReusableSkeleton from "../../../reuseable/reusableSekelton";


const Package = () => {
    return (
        <>
            <Header />
            <PackagePage />
            <Footer />
        </>
    )
}

const PackagePage = () => {
    const data = useGetPackageQuery();
    return (
        <>
            <div class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb-text">
                                <h2>Our Package</h2>
                                <div class="bt-option">
                                    <Link to="/">Home</Link>
                                    <span>Package</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="rooms-section spad">
                <div class="container">
                    <div class="row">
                        {
                            data !== undefined ?
                                <>
                                    {
                                        data.status === 'fulfilled' ?
                                            <>
                                                {
                                                    data?.data?.ids?.length !== 0 ?
                                                        <>
                                                            {
                                                                data.data.ids.map((key, index) => (
                                                                    <div class="col-lg-4 col-md-6" key={index}>
                                                                        <div class="room-item">
                                                                            <img src={data.data.entities[key].package_image} alt="" />
                                                                            <div class="ri-text">
                                                                                <h4>{data.data.entities[key].package_name}</h4>
                                                                                <h3>Rs. {data.data.entities[key].package_price * 1}<span>/Perperson</span></h3>
                                                                                <table>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="r-o">{data.data.entities[key].package_highlights}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <Link to={`/our/package/details/${data.data.entities[key].id}`} class="primary-btn">More Details</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </> : <>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="room-item">
                                                                    <ReusableSkeleton height={'644px'} width={'100%'} />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="room-item">
                                                                    <ReusableSkeleton height={'644px'} width={'100%'} />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="room-item">
                                                                    <ReusableSkeleton height={'644px'} width={'100%'} />
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </> : null
                                    }
                                </> : null
                        }
                        {/* <div class="col-lg-12">
                            <div class="room-pagination">
                                <a href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">Next <i class="fa fa-long-arrow-right"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Package;