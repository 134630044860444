import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


const roomAdapter = createEntityAdapter()

const initialState = roomAdapter.getInitialState()

export const roomSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRoom: builder.query({
            query: () => '/room/room/',
            transformResponse: responseData => {
                return roomAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Room', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Room', id }))
                ]
        }),
        getRoomPagination: builder.query({
            query: (id) => ({
              url: `/room/room-pagination/?page=${id}`,
              method: "GET",
            }),
            providesTags: ["Room"],
          }),
        getActiveRoom: builder.query({
            query: () => '/room/room/?is_active=True',
            transformResponse: responseData => {
                return roomAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Room', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Room', id }))
                ]
        }),
        getRoomById: builder.query({
            query: (id) => ({
                url: `/room/room/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Room']
        }),
        addRoom: builder.mutation({
            query: (imageGallery) => {
                return {
                    url: '/room/room/',
                    method: 'POST',
                    body: imageGallery
                }
            },
            invalidatesTags: ['Room']
        }),
        updateRoom: builder.mutation({

            query: (updateImageGallery) => {
                return {
                    url: `/room/room/${updateImageGallery.get('id')}/`,
                    method: 'PATCH',
                    body: updateImageGallery
                }
            },
            invalidatesTags: ['Room']
        }),
        deleteRoom: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/room/room/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['Room']
        }),
    })
})

export const {
    useAddRoomMutation,
    useDeleteRoomMutation,
    useGetActiveRoomQuery,
    useGetRoomByIdQuery,
    useGetRoomQuery,
    useUpdateRoomMutation,
    useGetRoomPaginationQuery,
} = roomSlice

// returns the query result object
export const selectRoomResult = roomSlice.endpoints.getRoom.select()

// Creates memoized selector
const selectroomData = createSelector(
    selectRoomResult,
    roomResult => roomResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllroom,
    selectById: selectroomById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = roomAdapter.getSelectors(state => selectroomData(state) ?? initialState)

