import { Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";
import AppCurrentVisits from "./chart/appCurrentVisit";
import AppWebsiteVisits from "./chart/appWebsiteVisits";


const DashboardHome = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <DashboardHomePage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const DashboardHomePage = () => {
    const theme = useTheme();
    return (
        <Box style={{ marginTop: '1.5rem' }}>
            <Typography style={{ fontSize: "27px", color: "black", fontWeight: "600", textAlign: "start" }}>
                Hi, Welcome Back
            </Typography>
            <Box style={{ marginTop: '2rem' }}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <AppWebsiteVisits
                            title="Website Visits"
                            subheader="(+43%) than last year"
                            chartLabels={[
                                '01/01/2003',
                                '02/01/2003',
                                '03/01/2003',
                                '04/01/2003',
                                '05/01/2003',
                                '06/01/2003',
                                '07/01/2003',
                                '08/01/2003',
                                '09/01/2003',
                                '10/01/2003',
                                '11/01/2003',
                            ]}
                            chartData={[
                                {
                                    name: 'Team A',
                                    type: 'column',
                                    fill: 'solid',
                                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                                },
                                {
                                    name: 'Team B',
                                    type: 'area',
                                    fill: 'gradient',
                                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                                },
                                {
                                    name: 'Team C',
                                    type: 'line',
                                    fill: 'solid',
                                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AppCurrentVisits
                            title="Current Visits"
                            chartData={[
                                { label: 'America', value: 4344 },
                                { label: 'Asia', value: 5435 },
                                { label: 'Europe', value: 1443 },
                                { label: 'Africa', value: 4443 },
                            ]}
                            chartColors={[
                                theme.palette.primary.main,
                                theme.palette.info.main,
                                theme.palette.warning.main,
                                theme.palette.error.main,
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default DashboardHome;