import './App.css';
import Routing from './routing/routing';
import './static/css/style.css';
import './static/css/bootstrap.min.css';
import './static/css/font-awesome.min.css';
import './static/css/elegant-icons.css';
import './static/css/flaticon.css';
import './static/css/owl.carousel.min.css';
import './static/css/select.css';
import "./static/css/jquery-ui.min.css";
import "./static/css/magnific-popup.css";
import "./static/css/slicknav.min.css";
import "./static/css/dashboard.css";



function App() {
  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
