import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../static/images/logo.png";
import { AiOutlineDashboard, AiOutlineFileImage, AiOutlineHome } from "react-icons/ai";
import { SiInstructure } from "react-icons/si";
import { BiBed } from "react-icons/bi";
import { FiPackage } from "react-icons/fi";
import { GrContact } from "react-icons/gr";



const Sidebar = () => {
    function IsActiveLink(linkPath) {
        const location = useLocation();
        return location.pathname === linkPath;
    }

    return (
        <Box className="ds_side_border">
            <Box className="ds_side_design">
                <Box>
                    <Typography style={{ fontWeight: "600", color: "#dfa974", fontSize: '17px', textAlign: 'start' }}><img src={Logo} width="18%" /></Typography>
                </Box>
                <Box style={{ marginTop: "1.5rem" }}>
                    <List>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/home" className={IsActiveLink('/dashboard/home') ? "ds_list_item" : "ds_list_item_non_active"}><AiOutlineDashboard size={18} style={{ marginRight: "8px" }} />Dashboard</Link>
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/home/page" className={IsActiveLink('/dashboard/home/page') ? "ds_list_item" : "ds_list_item_non_active"}><AiOutlineHome size={18} style={{ marginRight: "8px" }} />Home Page</Link>
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/about/page" className={IsActiveLink('/dashboard/about/page') ? "ds_list_item" : "ds_list_item_non_active"}><SiInstructure size={18} style={{ marginRight: "8px" }} />About</Link>
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/room/page" className={IsActiveLink('/dashboard/room/page') ? "ds_list_item" : "ds_list_item_non_active"}><BiBed size={18} style={{ marginRight: "8px" }} />Room</Link>
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/package/page" className={IsActiveLink('/dashboard/package/page') ? "ds_list_item" : "ds_list_item_non_active"}><FiPackage size={18} style={{ marginRight: "8px" }} />Package</Link>
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/contact/page" className={IsActiveLink('/dashboard/contact/page') ? "ds_list_item" : "ds_list_item_non_active"}><GrContact size={18} style={{ marginRight: "8px" }} />Contact</Link>
                        </ListItem>
                        <ListItem style={{ padding: '0px' }}>
                            <Link to="/dashboard/gallery/page" className={IsActiveLink('/dashboard/gallery/page') ? "ds_list_item" : "ds_list_item_non_active"}><AiOutlineFileImage size={18} style={{ marginRight: "8px" }} />Gallery</Link>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Box>
    )
}

export default Sidebar;