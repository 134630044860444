import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../static/images/logo.png";
import { useGetUserByIdQuery, useUserLogoutMutation } from "../../../api/userSlice";
import Cookies from "js-cookie";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";



const Header = () => {
    const [Show, setShow] = useState(false);
    const data = useGetUserByIdQuery(Cookies.get('id'));

    const [SuccessMessege, setSuccesMessege] = useState(null);
    const [ErrorMessege, setErrorMessege] = useState(null);

    const [UserLogout] = useUserLogoutMutation();


    const Logout = () => {
        // UserLogout({ refresh: Cookies.get('refresh') })
        //     .then((res) => {
        //         if (res.data) {
        Cookies.remove('refresh');
        Cookies.remove('access');
        Cookies.remove('user_email');
        Cookies.remove('id');
        setSuccesMessege('Logout successfully.')
        setTimeout(() => {
            setSuccesMessege(null)
        }, [3000])
        //     } else {
        //         setErrorMessege('Token expired.')
        //         setTimeout(() => {
        //             setErrorMessege(null)
        //         }, [3000])
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
    }

    return (
        <>
            <div className="offcanvas-menu-overlay"></div>
            <div style={{ alignSelf: "flex-end" }}>
                <div className="canvas-open" onClick={Show !== true ? () => setShow(true) : () => setShow(false)}>
                    <i className="icon_menu"></i>
                </div>
            </div>
            {
                Show === true ?
                    <div className={Show == true ? "offcanvas-menu-wrapper show-offcanvas-menu-wrapper" : "offcanvas-menu-wrapper"}>
                        <div className="canvas-close" onClick={() => setShow(false)}>
                            <i className="icon_close"></i>
                        </div>
                        {/* <div className="search-icon  search-switch">
                            <i className="icon_search"></i>
                        </div> */}
                        {
                            Cookies.get('id') &&
                            <div className="header-configure-area">
                                <div className="language-option">
                                    {/* <img src="img/flag.jpg" alt="" /> */}
                                    {
                                        data !== undefined ?
                                            <>
                                                {
                                                    data.status === 'fulfilled' ?
                                                        <>
                                                            <span>{data.data.first_name} {data.data.last_name} <i className="fa fa-angle-down"></i></span>
                                                        </> : null
                                                }
                                            </> : null
                                    }
                                    <div className="flag-dropdown">
                                        <ul>
                                            <li onClick={Logout}><a href="#">Logout</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        <nav className="slicknav_nav slicknav_hidden">
                            <ul style={{ padding: "0px", margin: "0px" }}>
                                <li ><Link to="/" role="menuitem" className="menuitem">Home</Link></li>
                                <li ><Link to="/our/rooms" role="menuitem" className="menuitem">Our Rooms</Link></li>
                                <li ><Link to="/" role="menuitem" className="menuitem">Our Packages</Link></li>
                                <li ><HashLink to="/#about_us" className="menuitem" style={{textDecoration:'none',borderBottom:'0px'}}>About Us</HashLink></li>
                                <li ><Link to="/contact" role="menuitem" className="menuitem">Contact</Link></li>
                                <li ><Link to="/gallery" role="menuitem" className="menuitem">Gallery</Link></li>
                                <li ><Link to="/login" role="menuitem" className="menuitem">Login</Link></li>
                                <li ><Link to="/signup" role="menuitem" className="menuitem">Sign Up</Link></li>
                            </ul>
                        </nav>
                        <div id="mobile-menu-wrap"></div>
                        <div className="top-social">
                            <a href="https://www.facebook.com/360degreeviewresort" target="_blanck"><i className="fa fa-facebook"></i></a>
                            <a href="https://www.instagram.com/view360resort/" target="_blanck"><i className="fa fa-instagram"></i></a>
                        </div>
                        <ul className="top-widget" >
                            <li><i className="fa fa-phone"></i> +977-9762288016</li>
                            <li><i className="fa fa-envelope"></i> view360resort@gmail.com</li>
                        </ul>
                    </div> : null
            }
            <header className="header-section">
                <div className="top-nav">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <ul className="tn-left">
                                    <li><i className="fa fa-phone"></i> +977-9762288016</li>
                                    <li><i className="fa fa-envelope"></i> view360resort@gmail.com</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <div className="tn-right">
                                    <div className="top-social">
                                        <a href="https://www.facebook.com/360degreeviewresort" target="_blanck"><i className="fa fa-facebook"></i></a>
                                        <a href="https://www.instagram.com/view360resort/" target="_blanck"><i className="fa fa-instagram"></i></a>
                                    </div>
                                    {
                                        Cookies.get('id') ?
                                            null : <a href="/login" className="bk-btn">Login Now</a>
                                    }
                                    {
                                        Cookies.get('id') ?
                                            <div className="language-option">
                                                {/* <img src="img/flag.jpg" alt="" /> */}
                                                {
                                                    data !== undefined ?
                                                        <>
                                                            {
                                                                data.status === 'fulfilled' ?
                                                                    <>
                                                                        <span>{data.data.first_name} {data.data.last_name} <i className="fa fa-angle-down"></i></span>
                                                                    </> : null
                                                            }
                                                        </> : null
                                                }
                                                <div className="flag-dropdown">
                                                    <ul>
                                                        <li onClick={Logout}><a href="#">Logout</a></li>
                                                    </ul>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-item">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2" style={{ textAlign: 'start' }}>
                                <div className="logo">
                                    <Link to="/" style={{ textDecoration: 'none' }}>
                                        <span style={{ fontSize: '20px', color: "#284f78", marginRight: '5px', fontWeight: '700' }}>View</span>
                                        <img src={Logo} style={{ height: "50px", width: '50px' }} alt="Logo" />
                                        <span style={{ fontSize: '20px', color: "#284f78", marginLeft: '5px', fontWeight: '700' }}>Resort</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="nav-menu">
                                    <nav className="mainmenu">
                                        <ul>
                                            <li ><Link to="/" style={{ textDecoration: "none" }}>Home</Link></li>
                                            <li><Link to="/our/rooms" style={{ textDecoration: "none" }}>Our Rooms</Link></li>
                                            <li><Link to="/our/package" style={{ textDecoration: "none" }}>Our Package</Link></li>
                                            <li><HashLink to="/#about_us" role="menuitem" className="menuitem" style={{textDecoration:'none',borderBottom:'0px'}}>About Us</HashLink></li>
                                            <li><Link to="/contact" style={{ textDecoration: "none" }}>Contact</Link></li>
                                            <li><Link to="/gallery" style={{ textDecoration: "none" }}>Gallery</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {SuccessMessege === null ? null : (
                    <Box className="successmessege">
                        <Typography className="messegefonts">
                            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                            {SuccessMessege}
                        </Typography>
                    </Box>
                )}
                {ErrorMessege === null ? null : (
                    <Box className="errormessege">
                        <Typography className="messegefonts">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="messegeicon"
                            />
                            {ErrorMessege}
                        </Typography>
                    </Box>
                )}
            </header>
        </>
    )
}

export default Header;