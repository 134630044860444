import React from "react";



const Footer = () => {
    return (
        <>
            <footer className="footer-section">
                <div className="container">
                    <div className="footer-text">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="ft-about" style={{ textAlign: 'start' }}>
                                    <div className="logo">
                                        <a href="#">
                                            <img src="img/footer-logo.png" alt="" />
                                        </a>
                                    </div>
                                    <p>
                                        The View 360 Resort offers breathtaking panoramic vistas that will leave you in awe from every angle.</p>
                                    <div className="fa-social">
                                        <a href="https://www.facebook.com/360degreeviewresort" target="_blanck"><i className="fa fa-facebook"></i></a>
                                        <a href="https://www.instagram.com/view360resort/" target="_blanck"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-youtube-play"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="ft-contact" style={{ textAlign: 'start' }}>
                                    <h6>Contact Us</h6>
                                    <ul>
                                        <li>+977-9762288016</li>
                                        <li>view360resort@gmail.com</li>
                                        <li>Kalu pandey, Chandragiri-1, Kathmandu</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="ft-newslatter" style={{ textAlign: 'start' }}>
                                    <h6>New latest</h6>
                                    <p>Get the latest updates and offers.</p>
                                    <form action="#" className="fn-form">
                                        <input type="text" placeholder="Email" />
                                        <button type="submit"><i className="fa fa-send"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-option">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div ><p>
                                    Copyright &copy; {new Date().getFullYear()} All rights reserved to View 360 Resort | Design and developed is made with <i className="fa fa-heart" aria-hidden="true"></i> by <a href="https://developergd.com" target="_blank">G & D Developer</a>
                                </p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;