import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import { Link } from "react-router-dom";
import { useGetRoomQuery } from "../../../api/roomSlice";
import ReusableSkeleton from "../../../reuseable/reusableSekelton";


const Room = () => {
    return (
        <>
            <Header />
            <RoomPage />
            <Footer />
        </>
    )
}

const RoomPage = () => {
    const data = useGetRoomQuery();
    return (
        <>
            <div class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb-text">
                                <h2>Our Rooms</h2>
                                <div class="bt-option">
                                    <Link to="/">Home</Link>
                                    <span>Rooms Details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="rooms-section spad">
                <div class="container">
                    <div class="row">
                        {
                            data !== undefined ?
                                <>
                                    {
                                        data.status === 'fulfilled' ?
                                            <>
                                                {
                                                    data?.data?.ids?.length !== 0 ?
                                                        <>
                                                            {
                                                                data?.data?.ids?.map((key, index) => (
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="room-item">
                                                                            <img src={data?.data?.entities[key].room_image} alt="room_image" />
                                                                            <div class="ri-text">
                                                                                <h4>{data?.data?.entities[key].room_name}</h4>
                                                                                <h3>Rs. {data?.data?.entities[key].price}<span>/Pernight</span></h3>
                                                                                <table>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="r-o">Capacity:</td>
                                                                                            <td>{data?.data?.entities[key].capacity}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td class="r-o">Bed:</td>
                                                                                            <td>{data?.data?.entities[key].bed_type}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td class="r-o">Services:</td>
                                                                                            <td>{data?.data?.entities[key].services}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <Link to={`/our/rooms/details/${data?.data?.entities[key].id}`} class="primary-btn">More Details</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </> : <>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="room-item">
                                                                    <ReusableSkeleton height={'644px'} width={'100%'} />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="room-item">
                                                                    <ReusableSkeleton height={'644px'} width={'100%'} />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="room-item">
                                                                    <ReusableSkeleton height={'644px'} width={'100%'} />
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </> : null
                                    }
                                </> : null
                        }
                        {/* <div class="col-lg-12">
                            <div class="room-pagination">
                                <a href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">Next <i class="fa fa-long-arrow-right"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Room;