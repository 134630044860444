import { SearchInput } from "./controls/searchInput";
import Input from "./controls/input";
import Image from "./controls/image";
import TextEditor from "./controls/Editor";


const Controls = {
    SearchInput,
    Input,
    Image,
    TextEditor,
}

export default Controls;