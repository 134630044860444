import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { BiCog } from "react-icons/bi";
import { MdNotifications } from 'react-icons/md';
import Profile from "../../../static/images/profile.png";
import { useUserLogoutMutation } from "../../../api/userSlice";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";



const Header = () => {
    const [SuccessMessege, setSuccesMessege] = useState(null);
    const [ErrorMessege, setErrorMessege] = useState(null);

    const [UserLogout] = useUserLogoutMutation();
    const history = useNavigate();


    const Logout = () => {
        // UserLogout({ refresh: Cookies.get('refresh') })
        //     .then((res) => {
        //         if (res.data) {
        Cookies.remove('refresh');
        Cookies.remove('access');
        Cookies.remove('user_email');
        Cookies.remove('id');
        setSuccesMessege('Logout successfully.')
        setTimeout(() => {
            history('/')
        }, [3000])
        //     } else {
        //         setErrorMessege('Token expired.')
        //         setTimeout(() => {
        //             setErrorMessege(null)
        //         }, [3000])
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
    }
    return (
        <Box >
            <Grid container spacing={0}>
                <Grid item xs={10.7}>
                    <Typography style={{ fontSize: "18px", color: 'black', fontWeight: '600', textAlign: "start", marginTop: "4px" }}>Dashboard</Typography>
                </Grid>
                <Grid item xs={1.2}>
                    <Grid container spacing={3}>
                        <Grid item xs={3.3}>
                            <Box className="dh_cog_design">
                                <BiCog size={22} color="#637481" />
                            </Box>
                        </Grid>
                        <Grid item xs={3.3}>
                            <Box className="dh_cog_design">
                                <MdNotifications size={22} color="#637481" />
                            </Box>
                        </Grid>
                        <Grid item xs={5.4}>
                            <div className="language-option1" style={{ zIndex: 2 }}>
                                <img src={Profile} style={{ width: '100%' }} alt="profile" />
                                <div className="flag-dropdown1" style={{ zIndex: 2 }}>
                                    <ul>
                                        <li style={{ listStyle: 'none', textAlign: 'start' }} onClick={Logout}><p className="logout-dashboard-design">Logout</p></li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {SuccessMessege === null ? null : (
                <Box className="successmessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                        {SuccessMessege}
                    </Typography>
                </Box>
            )}
            {ErrorMessege === null ? null : (
                <Box className="errormessege">
                    <Typography className="messegefonts">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="messegeicon"
                        />
                        {ErrorMessege}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default Header;