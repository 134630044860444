import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, is_admin: null },
    reducers: {
        setCredentials: (state, action) => {
            const { email, tokens, is_admin } = action.payload
            state.user = email
            state.token = tokens
            state.is_admin = is_admin
        },
        logOut: (state, action) => {
            state.user = null
            state.token = null
            state.is_admin = null
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
