import { Box, Button, Card, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useGetContactQuery } from "../../../../api/contactSlice";
import { useDeleteRoomMutation, useGetRoomPaginationQuery } from "../../../../api/roomSlice";
import Controls from "../../../../form/controls";
import { getPaginationPagesCount } from "../../../../form/utils/getPaginationPagesCount";
import BasicTable from "../../../../reuseable/basicTable";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";



const DashboardContactPage = () => {
    return (
        <Box className="ds_background_color">
            <Grid container spacing={0}>
                <Grid item xs={2.2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={9.8}>
                    <Box style={{ padding: ".9rem", marginLeft: '1.5rem', marginRight: "1.5rem" }}>
                        <Header />
                        <DashboardContactPagePage />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const DashboardContactPagePage = () => {
    const { data, isLoading, isSuccess } = useGetContactQuery();

    console.log(data);


    let headers = [];
    let tableData = [];
    let content;
    if (isLoading) {
        content = <p>"Loading..."</p>;
    } else if (isSuccess) {
        headers = ["Full Name", 'Email', "Contact Number", "Subject", "Message"];
        tableData = [];
        data.ids.forEach((key) => {
            tableData.push({
                'Full Name': data.entities[key].full_name,
                'Email': data.entities[key].email,
                'Contact Number': data.entities[key].contact_no,
                id: data.entities[key].id,
                Message: data.entities[key].message,
                Subject: data.entities[key].subject,
            });
        });
    }

    console.log(tableData);


    return (
        <Box>
            <Box style={{ marginTop: '2rem' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography style={{ fontSize: "22px", color: "black", fontWeight: "600", textAlign: "start" }}>
                        Contact Page Data
                    </Typography>
                    {/* <Button variant="contained" onClick={pageChange} style={{ fontSize: '12px', fontWeight: '600', padding: '10px', paddingLeft: '13px', paddingRight: "13px" }} startIcon={<BiPlus />}>
                        Add Data
                    </Button> */}
                </Stack>
                <Box style={{ marginTop: '2rem' }}>
                    <Card>
                        <Box style={{ textAlign: 'start', padding: '1.5rem' }}>
                            <Controls.SearchInput fliterPlaceholder={"Search...."} />
                        </Box>
                        <BasicTable
                            headers={headers}
                            data={tableData}
                            isDelete={false}
                            deleteColor={"#f50057"}
                            isEdit={false}
                            editColor={"#3f51b5"}
                            addColor={""}
                            tableName={"room/page"}
                            // deleteConfirm={deleteConfirm}
                            actions={false}
                            paginationData={false}
                        // activeSign={["Active"]}
                        />
                    </Card>
                </Box>
            </Box>
        </Box>
    )
}
export default DashboardContactPage;